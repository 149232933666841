.container {
    width: min(1170px, 100% - 2rem);
    margin-inline: auto;
}

.custom-background {
    background-image: url("./img/beams-home@95.jpg");
    background-position: center;
    background-repeat: no-repeat;
}

.solid {
    background: linear-gradient(-45deg, #EE7752, #E73C7E, #e0470b, );
}

.gradient {
    background: linear-gradient(-45deg, #EE7752, #E73C7E, #e0470b);
    background-size: 400% 400%;
    -webkit-animation: Gradient 15s ease infinite;
    -moz-animation: Gradient 15s ease infinite;
    animation: Gradient 15s ease infinite;
}
  
@-webkit-keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
    50% {
    background-position: 100% 50%
    }
    100% {
    background-position: 0% 50%
    }
}
  
@-moz-keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
    50% {
    background-position: 100% 50%
    }
    100% {
    background-position: 0% 50%
    }
}
  
@keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
    50% {
    background-position: 100% 50%
    }
    100% {
    background-position: 0% 50%
    }
}

.PhoneInputInput {
    background: none;
    color: white;
}